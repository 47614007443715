<template>
  <div style="margin: 4rem auto; max-width: 600px; width: 100%">
    <div class="card">
      <div class="card-body text-center">
        <i class="fas fa-ban fa-4x text-danger"></i>
        <h2 class="text-danger">Acesso negado</h2>
        <p>
          Prezado usuário, você não tem autorização para acessar este recurso.
        </p>
        <button
          type="button"
          class="btn btn-link"
          @click="retornarParaTelaInicial()"
        >
          Ir para tela inical do sistema
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    retornarParaTelaInicial() {
      this.$router.push({ name: "DashboardPadrao" });
    },
  },
};
</script>
